// EditarProductoModal.js
import React, { useState } from 'react';

function EditarProductoModal({ productoEditar, cerrarEditar, actualizarProducto }) {
  const [producto, setProducto] = useState(productoEditar);

  const handleChange = (e) => {
    setProducto({
      ...producto,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    actualizarProducto(producto);
  }

  

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' >
      <form onSubmit={handleSubmit} className='relative bg-white p-4 rounded shadow-lg w-50'>
        <button type='button' className='absolute top-2 right-2 bg-red-500 text-white py-1 px-2.5  rounded-full' onClick={cerrarEditar}>X</button>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Nombre</label>
          <input type='text' name='nombre' value={producto.nombre} onChange={handleChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Precio</label>
          <input type='number' name='precio' value={producto.precio} onChange={handleChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Sabor</label>
          <input type='text' name='sabor' value={producto.sabor} onChange={handleChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Categoria</label>
          <input type='text' name='categoria' value={producto.categoria} onChange={handleChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Descripcion</label>
          <textarea name='descripcion' value={producto.descripcion} onChange={handleChange} className='h-32 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' /></div>
        
        <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Actualizar Producto</button>
      </form>
    </div>
  );
}

export default EditarProductoModal;