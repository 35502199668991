import React, { useState, useEffect } from "react";
import axios from "axios";
import { API, APIupload, APIdelete, LinkGuardar } from "../../accesos/API";
import { ReactComponent as TrashIcon } from "../../multimedia/trash.svg";


function UploadDeleteFiles({
  cerrarImagenEditar,
  imagenEditar
}) {
  const [producto, setProducto] = useState(imagenEditar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [shouldSendRequest, setShouldSendRequest] = useState(false);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedFile) {
      alert("Primero debes seleccionar un archivo.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("productId", producto.id);

    var newLinkImageProduct =
      LinkGuardar +
      producto.id +
      "/" +
      selectedFile.name.replace(/\s/g, "%20");

    setProducto({
      ...producto,
      imagen:
        producto.imagen.length > 3
          ? producto.imagen + ", " + newLinkImageProduct
          : newLinkImageProduct,
    });

    await axios.post(APIupload, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        setUploadPercentage(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      },
    });
    setLoading(false);
    setShouldSendRequest(true);
  };

  useEffect(() => {
    if (shouldSendRequest) {
      axios.put(`${API}?id=${producto.id}`, producto);
      setShouldSendRequest(false);
    }
  }, [shouldSendRequest]);

  const handleDelete = async (e) => {
    e.preventDefault();

    const confirmation = window.confirm(
      "¿Estás seguro de que quieres eliminar esta imagen?"
    );
    if (!confirmation) {
      return;
    }

    const filename = e.target.getAttribute('mso').split("/").pop().trim();
    const productId = producto.id;
    const LinkAEliminarDeProduct = e.target.getAttribute('mso');

    const url = new URL(APIdelete);
    url.search = new URLSearchParams({ filename, productId });

    const response = await axios.delete(url.toString());
    console.log(response.data);

    const newLinkImageProduct = producto.imagen
      .split(",")
      .filter((link) => link !== LinkAEliminarDeProduct)
      .join(", ");
    setProducto({
      ...producto,
      imagen: newLinkImageProduct,
    });

    setShouldSendRequest(true);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <form
        onSubmit={handleSubmit}
        className="relative bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3"
      >
        <h1 className="text-2xl font-bold mb-4 bg-gray-800 text-white p-2 rounded">
          Subir Imagenes
        </h1>
        <hr className="mb-4" />
        <h2 className="text-sm font-semibold text-gray-600">
          Solo se permite subir los siguientes formatos: "jpg, png, jpeg, gif"
        </h2>
        <h2 className="text-sm text-gray-600">
          Los archivos no deben pesar mas de 20MB
        </h2>
        <button
          type="button"
          className="absolute top-2 right-2 bg-red-500 text-white py-1 px-2.5 rounded-full"
          onClick={cerrarImagenEditar}
        >
          X
        </button>

        <hr className="my-4" />
        <input
          type="file"
          onChange={handleFileChange}
          className="py-2 px-3 border rounded mb-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300"
        />

        <button
          type="submit"
          className="bg-green-500 text-white py-2 px-4 rounded-full"
        >
          Subir
        </button>
        {loading && <p className="mt-4">Cargando... {uploadPercentage}%</p>}
        <hr className="my-4" />
        <h2 className="text-sm font-semibold text-gray-800">
          Imagenes actuales:
        </h2>
        <div className="grid grid-cols-3 gap-4 max-w-full mt-4">
          {producto.imagen.split(",").map((imagen, index) => (
            <div key={index} className="relative group">
              <img
                src={imagen}
                alt={imagen}
                mso={imagen}
                className="w-full h-20 object-cover cursor-pointer"
                onClick={handleDelete}
              />
              <div
                mso={imagen}
                onClick={handleDelete}
                className="cursor-pointer absolute inset-0 bg-white/50 flex items-center justify-center  hover:scale-105 transform transition-transform duration-200 hover:bg-white/70"
              >
                <TrashIcon mso={imagen} className="text-6xl text-red-600" />
                <p mso={imagen} className="font-semibold text-red-600">Eliminar</p>
              </div>
            </div>
          ))}
        </div>
      </form>
    </div>
  );
}

export default UploadDeleteFiles;
